<template>
  <div id="all-users">
    <vue-headful :title="title + ($appName ? ' | ' + $appName : '')" />
    <b-container>
      <h1>User List</h1>

      <!-- Search section -->
      <b-form-group class="mb-4" @keydown.native.esc="clearSearch">
        <b-input-group>
          <b-form-input v-model="filter" placeholder="Search by user email or organisation" />
          <b-btn @click="clearSearch">Clear</b-btn>
        </b-input-group>
      </b-form-group>

      <b-pagination
        v-show="filteredUsers.length > perPage"
        class="float-right"
        v-model="currentPage"
        :total-rows="filteredUsers.length"
        :per-page="perPage"
        aria-controls="my-table"
      ></b-pagination>

      <b-table
        busy.sync="loading"
        show-empty
        :items="filteredUsers"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <template slot="empty">
          <div v-if="loading" class="text-center">
            <Busy primary text="Loading..." />
          </div>
          <div v-else class="text-center">No users found.</div>
        </template>

        <template v-slot:cell(lastExtractOrganisationName)="row">{{
          orgShortName(row.value)
        }}</template>
        <template v-slot:cell(actions)="row">
          <b-dropdown text="Actions" lazy>
            <b-dropdown-header>{{ row.item.email }}</b-dropdown-header>
            <b-dropdown-item
              :to="{
                path: '/dashboard?userid=' + row.item.userId
              }"
              target="_blank"
            >
              <font-awesome-icon :icon="['fas', 'narwhal']" class="text-muted mr-2" />Dashboard
            </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: 'user-extracts',
                params: { userId: row.item.userId }
              }"
            >
              <font-awesome-icon :icon="['fas', 'cubes']" class="text-muted mr-2" />Extracts
            </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: 'user-account',
                params: { userId: row.item.userId }
              }"
            >
              <font-awesome-icon :icon="['fas', 'user']" class="text-muted mr-2" />Account
            </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: 'admin-consent-lists',
                params: { userId: row.item.userId }
              }"
            >
              <font-awesome-icon :icon="['fas', 'user-check']" class="text-muted mr-2" />Consent
              Lists
            </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: 'admin-savings-explorer',
                params: { userId: row.item.userId }
              }"
            >
              <font-awesome-icon :icon="['fas', 'rocket-launch']" class="text-muted mr-2" />Savings
              Explorer
            </b-dropdown-item>
            <b-dropdown-divider />
            <b-dropdown-item @click.stop="addOrganisations(row.item, 'me')"
              >Add organisations to me</b-dropdown-item
            >
            <b-dropdown-item @click.stop="removeOrganisations(row.item, 'me')"
              >Remove organisations from me</b-dropdown-item
            >
            <b-dropdown-item @click.stop="addOrganisations(row.item, null)"
              >Add organisations to...</b-dropdown-item
            >
            <b-dropdown-item @click.stop="removeOrganisations(row.item, null)"
              >Remove organisations from...</b-dropdown-item
            >
            <b-dropdown-divider />
            <b-dropdown-item v-if="row.item.cpmId" @click.stop="unsetCpmRole(row.item)"
              >Remove CPM role</b-dropdown-item
            >
            <b-dropdown-item v-if="!row.item.cpmId" @click.stop="setCpmRole(row.item)"
              >Assign CPM role</b-dropdown-item
            >
            <b-dropdown-item v-if="row.item.apmId" @click.stop="unsetApmRole(row.item)"
              >Remove APM role</b-dropdown-item
            >
            <b-dropdown-item v-if="!row.item.apmId" @click.stop="setApmRole(row.item)"
              >Assign APM role</b-dropdown-item
            >
            <b-dropdown-item v-if="!row.item.opsId" @click.stop="setOpsRole(row.item)"
              >Assign Ops role</b-dropdown-item
            >
            <b-dropdown-item v-if="row.item.opsId" @click.stop="unsetOpsRole(row.item)"
              >Remove Ops role</b-dropdown-item
            >
          </b-dropdown>
        </template>
        <template v-slot:cell(lastExtractJob)="row">{{ formatDate(row.value) }}</template>
        <template v-slot:cell(createdDate)="row">{{ formatDate(row.value) }}</template>
        <template v-slot:cell(roles)="row"
          ><b-badge class="user-role-badge" v-if="row.item.cpmId">CPM</b-badge
          ><b-badge class="user-role-badge" v-if="row.item.apmId">APM</b-badge>
          <b-badge class="user-role-badge" v-if="row.item.opsId">Ops</b-badge></template
        >
      </b-table>
    </b-container>
    <b-modal
      :title="userPicker.title"
      :ok-title="userPicker.ok"
      @ok="handleOk"
      @hidden="handleHidden"
      @keydown.native.enter="handleOk"
      :visible="userPicker.show"
    >
      <p>{{ userPicker.message }}</p>
      <p class="small" v-if="userPicker.action == 'removeorganisations'">
        The user selected in the dropdown bellow is the one to remove organisations from.
      </p>
      <p class="small" v-if="userPicker.action == 'removeorganisations'">
        The organisations to be removed are obtained from the user with the email address above.
      </p>
      <b-form-group :label="userPicker.userLabel" label-for="cboToUser">
        <b-form-select
          class="mb-2"
          id="cboToUser"
          v-model="userPicker.toUserId"
          :options="sortedUsersByEmail"
          placeholder="Select a user"
          :state="userPicker.isUserSelected"
          value-field="userId"
          text-field="email"
        />
        <b-form-invalid-feedback class="inline-error">Please select a user</b-form-invalid-feedback>
      </b-form-group>
    </b-modal>
  </div>
</template>

<style lang="scss">
@import "@/styles/common.scss";

.user-role-badge {
  background-color: $color-pink-main;
  margin-right: 5px;
}
</style>

<script>
import { EventBus } from "@/components/eventbus";
import ApiHelper from "@/helper/apihelper";
import FormatHelper from "@/helper/formathelper";
import Console from "@/console";
import Busy from "@/components/Busy";

export default {
  name: "all-users",
  components: { Busy },
  data() {
    return {
      title: "Users",
      loading: true,
      sortBy: "lastExtractJob",
      sortDesc: true,
      users: [],
      sortedUsersByEmail: [],
      filter: null,
      perPage: 50,
      currentPage: 1,
      fields: [
        { key: "actions", label: "Actions", class: "text-center align-middle" },
        {
          key: "email",
          label: "User Email",
          sortable: true,
          class: "text-center align-middle"
        },
        {
          key: "lastExtractOrganisationName",
          label: "Last Organisation",
          class: "text-center align-middle"
        },
        {
          key: "lastExtractJob",
          label: "Last Extract",
          sortable: true,
          class: "text-center align-middle"
        },
        {
          key: "createdDate",
          label: "Created",
          sortable: true,
          class: "text-center align-middle"
        },
        {
          key: "roles",
          label: "Roles",
          class: "text-center align-middle"
        }
      ],
      userPicker: {
        show: false,
        fromUserId: null,
        toUserId: null,
        message: null,
        title: null,
        ok: null,
        userLabel: null,
        action: null,
        isUserSelected: null
      }
    };
  },
  computed: {
    filteredUsers() {
      //if the user set changes, reset the pager to page 1
      this.resetPaging;
      //lastExtractOrganisationName
      let result = this.users;
      if (this.filter) {
        result = this.users.filter(user => {
          if (
            user.email.toLowerCase().includes(this.filter.toLowerCase()) ||
            (typeof user.lastExtractOrganisationName != "undefined" &&
              user.lastExtractOrganisationName.toLowerCase().includes(this.filter.toLowerCase()))
          )
            return true;
          return false;
        });
      }

      return result;
    }
  },
  created() {
    this.fetchData();
  },
  watch: {
    $route: "fetchData"
  },
  methods: {
    formatDate: FormatHelper.formatDate,
    orgShortName: FormatHelper.orgShortName,

    resetPaging() {
      this.currentPage = 1;
    },

    //CLEAR SEARCH
    clearSearch() {
      this.filter = "";
    },

    sortUsersByEmail(users) {
      users.sort((a, b) => {
        let result = 0;
        if (a.email.toLowerCase() < b.email.toLowerCase()) {
          result = -1;
        } else if (a.email.toLowerCase() > b.email.toLowerCase()) {
          result = 1;
        }
        return result;
      });

      return users;
    },

    sortUsersByLastExtract(users) {
      users.sort((a, b) => {
        let result = 0;
        if (a.lastExtractJob < b.lastExtractJob) {
          result = -1;
        } else if (a.lastExtractJob > b.lastExtractJob) {
          result = 1;
        }
        return result;
      });

      return users;
    },

    async fetchData() {
      this.loading = true;

      try {
        const client = await ApiHelper.http();
        const response = await client.get(`${ApiHelper.endPoint()}users`);

        if (response.data.status === "success") {
          this.loading = false;
          Console.log("Users: ", response.data.users);
          this.users = response.data.users.slice();
          this.sortedUsersByEmail = this.sortUsersByEmail(response.data.users.slice());
        } else {
          this.showWarning("There was a problem getting users.");
        }
      } catch {
        this.showWarning("There was a problem getting users.");
      }
    },
    showSuccess(message) {
      EventBus.$emit("show-toast", { message: message, variant: "success" });
    },
    showWarning(message) {
      EventBus.$emit("show-toast", { message: message, variant: "warning" });
    },
    async addOrganisations(fromUser, toUser) {
      if (toUser == "me") {
        this.callDevMethod("Add", fromUser.userId);
      } else {
        this.showUserPicker("Add", fromUser);
      }
    },
    async removeOrganisations(fromUser, toUser) {
      if (toUser == "me") {
        this.callDevMethod("Remove", fromUser.userId);
      } else {
        this.showUserPicker("Remove", fromUser);
      }
    },
    async callDevMethod(action, userId, newUserId) {
      const url = `${ApiHelper.endPoint()}users/${userId}/organisations`;
      const query = newUserId ? `?newUserId=${newUserId}` : "";
      let response;

      try {
        const client = await ApiHelper.http();

        if (action === "Add") {
          response = await client.post(url + query);
        } else if (action === "Remove") {
          response = await client.delete(url + query);
        } else {
          Console.error("Unrecognized action", action);
        }

        Console.log(response);

        if (response.data.status === "success") {
          let message = `Action "${action}" performed successfully.`;
          if (response.data.output) {
            message += "<br><br>The following information was returned:\n";
            message += response.data.output.replace(/(?:\r\n|\r|\n)/g, "<br>");
          }

          EventBus.$emit("show-toast", {
            message: message,
            variant: "success"
          });
        }
      } catch (err) {
        Console.error(err);
        EventBus.$emit("show-toast", {
          message: `Action "${action}" failed, (${response.data.error.code}: ${response.data.error.message})`,
          variant: "warning"
        });
      }
    },
    showUserPicker(action, fromUser) {
      this.userPicker.fromUserId = fromUser.userId;
      this.userPicker.show = true;
      this.userPicker.isUserSelected = null;
      this.userPicker.toUserId = null;
      this.userPicker.message = `${action} (${fromUser.email}) user's organisations`;

      this.userPicker.ok = action;
      this.userPicker.title = "Select user";
      this.userPicker.userLabel = action == "Add" ? "To user" : "From user";
      this.userPicker.action = action;
    },
    async handleOk(event) {
      event.preventDefault();
      if (this.isValidUserSelected()) {
        this.userPicker.show = false;

        this.callDevMethod(
          this.userPicker.action,
          this.userPicker.fromUserId,
          this.userPicker.toUserId
        );
      }
    },
    isValidUserSelected() {
      if (this.userPicker.toUserId) {
        this.userPicker.isUserSelected = true;
      } else {
        this.userPicker.isUserSelected = false;
      }

      return this.userPicker.isUserSelected;
    },
    handleHidden() {
      this.userPicker.show = false;
    },
    async saveUser(user) {
      try {
        let client = await ApiHelper.http();
        var response = await client.post(`${ApiHelper.endPoint()}users/${user.userId}`, {
          user: user
        });
        if (response.data.status === "success") {
          this.showSuccess("User account details saved successfully");
        } else {
          this.showWarning("There was a problem updating the user account details.");
        }
      } catch {
        this.showWarning("There was a problem saving user account details.");
      }
    },
    unsetCpmRole(user) {
      this.$set(user, "cpmId", null);
      this.saveUser(user);
    },
    setCpmRole(user) {
      this.$set(user, "cpmId", user.userId);
      this.saveUser(user);
    },
    unsetApmRole(user) {
      this.$set(user, "apmId", null);
      this.saveUser(user);
    },
    setApmRole(user) {
      this.$set(user, "apmId", user.userId);
      this.saveUser(user);
    },
    setOpsRole(user) {
      this.$set(user, "opsId", user.userId);
      this.saveUser(user);
    },
    unsetOpsRole(user) {
      this.$set(user, "opsId", null);
      this.saveUser(user);
    }
  }
};
</script>
